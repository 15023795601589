* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans",
    sans-serif;
    font-size: 20px;
    color: #444;
    text-align: center;
    line-height: 1.5;
  }
  
  nav,
  footer {
    padding: 1% 0;
    background-color: #aaa;
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  
  header {
    padding: 30px 15px;
  }
  
  section {
    min-height: 200px;
    padding: 15px;
    background-color: #6B7A8F;
    color:white;
  }
  
  section:nth-child(even) {
    background-color:#DCC7AA;
    color: #444;
  }
  
  p {
    margin: 0 auto;
    max-width: 650px;
  }

  .to_courts_button {
    text-decoration: none;
    width: 20%;
    border-style: solid; border-width: 1px;
    margin-bottom: 5px;
    background-color: white;
    border-color: #F7882F;
    border-radius: 5px;
    color:#F7882F;
    font-family: 'Lato', sans-serif;
    padding: 5px;
    vertical-align: center;

}
.to_courts_button:hover {
    color: white;
    background-color: #F7882F;
}

.to_courts {
  display: flex;
  align-items: center;
  justify-content: center;
}