.TeamBuildingForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    background-color: #6B7A8F;
    color: white;
    border-radius: 10px;
}

label, input {
    margin: 8px;
}

input, select {
    height: 30px;
    width: 50%;
}


.button {
    width: 20%;
    color:#F7882F;
    background-color: white;
    border-color: #F7882F;
    border-radius: 5px;
    font-size: 20px;
}

.button:hover {
    color: white;
    background-color: #F7882F;
}