.court{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    border: 1px solid;
    border-radius: 10px;
    background-color: #6B7A8F;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.button {
    width: 20%;
    color:#F7882F;
    background-color: white;
    border-color: #F7882F;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 5px;
}

.button:hover {
    color: white;
    background-color: #F7882F;
}

.likeAButton {
    text-decoration: none;
    width: 20%;
    border-style: solid; border-width: 1px;
    margin-bottom: 5px;
    background-color: white;
    border-color: #F7882F;
    border-radius: 5px;
    color:#F7882F;
    font-family: 'Lato', sans-serif;
}
.likeAButton:hover {
    color: white;
    background-color: #F7882F;
}

.next_team {
    margin-bottom: 5px;
}



