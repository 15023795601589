.Facility_list {
    display: flex;
    flex-direction: column;
    align-items:center;
    list-style-type: none;
    margin-bottom: 10px;
    padding: 0;
}

.facility {
    background-color: #DCC7AA;
}

.Facility_court {
    width: 100%;
}




  